import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import Routes from "../src/Router";
import GlobalStyle from "../src/Modules/GlobalStyle";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./StateManagement/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("Patient_Portal"));
root.render(
  <StrictMode>
    <GoogleOAuthProvider clientId="85183878694-g1gpl3f2h9o5qob9p2d7p4rb47q27vaj.apps.googleusercontent.com">
      <Provider store={store}>
        <Router>
          <GlobalStyle />
          <Routes />
        </Router>
      </Provider>
    </GoogleOAuthProvider>
  </StrictMode>
);
