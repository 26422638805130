import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Success from "../../Modules/StyledComponents/Toastify/Success";
import moment from "moment";

export const AppointmentsSlice = createSlice({
  name: "Appointments",
  initialState: {
    providersList: [],
    loadingProviders: false,
    loadingOffice: false,
    loadingSave: false,
    openAppointment: false,
    timeError: {
      noTime: false,
      noProvider: false,
    },
    appointmentData: {
      provider: "",
      office: "",
      speciality: "",
      date: "",
      time: "",
      reason: "",
      singleProvider: [],
      singleOffice: [],
      aptdate: moment().format("YYYY-MM-DD"),
      apttime: "",
      showSelector: true,
      timeSelected: false,
      showMessage: false,
      messageType: "success",
      message: "",
      encounter: 0,
      changeUrl: "",
      eid: 0,
      cancelID: 0,
      appttype: "",
    },
    sliderObject: {
      morning: false,
      afternoon: false,
      evening: false,
    },
    facilityList: [],
    Loading: false,
    Refresh: "",
    PastAppt: [],
    CancelAppt: {},
    upcomingAppointment: [],
    providerAvailablity: [],
    loadAvailablity: false,
    RefreshAppt: false,
    pastApptLoading: false,
    portalAppAvailablity: "",
    OnsiteAppReq: "",
    BillingFacility: "",
  },
  reducers: {
    GetUpcomingAppointment: (state) => {
      state.Loading = true;
      state.Refresh = "";
    },

    SetUpcomingAppointment: (state, action) => {
      state.upcomingAppointment = action.payload;
      state.Loading = false;
    },
    GetPastAppt: (state) => {
      state.pastApptLoading = true;
    },
    SetPastAppt: (state, action) => {
      state.PastAppt = action.payload;
      state.pastApptLoading = false;
    },
    GetCancelAppt: (cancelreason) => {
      return cancelreason;
    },

    SetCancelAppt: (state, action) => {
      state.CancelAppt = action.payload;
      state.RefreshAppt = !state.RefreshAppt;
      state.appointmentData["cancelID"] = 0;
      toast(<Success msg={"Appointment Cancelled Successfully !"} />, {
        containerId: "A",
      });
    },

    getProvidersList: (state) => {
      state.loadingProviders = true;
    },
    setProvidersList: (state, action) => {
      state.providersList = action.payload;
      state.loadingProviders = false;
    },
    clearInput: (state, action) => {
      if (action.payload === "provider") {
        state.appointmentData["singleProvider"] = [];
        state.appointmentData["provider"] = "";
        state.appointmentData["aptdate"] = moment().format("YYYY-MM-DD");
        state.appointmentData["apttime"] = "";
        state.appointmentData["showSelector"] = true;
        state.appointmentData["timeSelected"] = false;
      } else if (action.payload === "office") {
        state.appointmentData["singleOffice"] = [];
        state.appointmentData["singleProvider"] = [];
        state.appointmentData["office"] = "";
        state.appointmentData["provider"] = "";
        state.appointmentData["speciality"] = "";
        state.appointmentData["appttype"] = "";
        state.appointmentData["aptdate"] = moment().format("YYYY-MM-DD");
        state.appointmentData["apttime"] = "";
        state.appointmentData["showSelector"] = true;
        state.appointmentData["timeSelected"] = false;
      } else {
        state.appointmentData["apttime"] = "";
        state.appointmentData["showSelector"] = true;
        state.appointmentData["timeSelected"] = false;
      }
    },
    changeLoadingState: (state, action) => {
      let x = action.payload;
      if (x.name === "loadProvider") {
        state.loadingProviders = x.value;
      }
      if (x.name === "loadOffice") {
        state.loadingOffice = x.value;
      }
    },
    changeAppointmentData: (state, action) => {
      let x = action.payload;
      if (x.name === "select_provider") {
        state.appointmentData["provider"] = x.value.id;
        state.appointmentData["singleProvider"] = x.value;
        state.appointmentData["searchProvider"] = "";
        state.timeError["noProvider"] = false;
      } else if (x.name === "select_office") {
        state.appointmentData["office"] = x.value.id;
        state.appointmentData["singleOffice"] = x.value;
        state.appointmentData["searchOffice"] = "";
      } else {
        state.appointmentData[x.name] = x.value;
      }
    },
    clearAppointmentData: (state, action) => {
      state.appointmentData = {
        provider: "",
        office: "",
        date: "",
        time: "",
        reason: "",
        singleProvider: [],
        singleOffice: [],
        aptdate: moment().format("YYYY-MM-DD"),
        apttime: "",
        showSelector: true,
        timeSelected: false,
        showMessage: false,
        messageType: "success",
        message: "",
        encounter: 0,
        changeUrl: action.payload,
        eid: 0,
        cancelID: 0,
        appttype: "",
        speciality: "",
      };
    },
    getFacilityList: (state) => {
      state.loadingOffice = true;
    },
    setFacilityList: (state, action) => {
      state.loadingOffice = false;
      state.facilityList = action.payload;
    },
    addAppointment: (state) => {
      state.loadingSave = true;
    },
    AppointmentEdited: (state) => {
      state.loadingSave = true;
    },
    appointmentSaved: (state, action) => {
      state.loadingSave = false;
      let data = action.payload;
      state.appointmentData["message"] = data.message;
      state.appointmentData["messageType"] = data.color;
      state.appointmentData["showMessage"] = true;
    },
    changeSlider: (state, action) => {
      let x = action.payload;
      const newState = { ...state.sliderObject };
      newState[x.name] = x.value;
      state.sliderObject = newState;
    },
    changeErrorObject: (state, action) => {
      state.timeError[action.payload.name] = action.payload.value;
      if (action.payload.name === "noProvider") {
        state.appointmentData["timeSelected"] = false;
        state.appointmentData["showSelector"] = true;
      }
    },
    EditAppointment: (state, action) => {
      let data = action.payload;
      let a = {
        img_url: data.img_url,
        name: data.provider_name,
        designation: data.title,
      };
      let b = {
        name: data.facility_name,
        facility_address: data.facility_address_2,
        telehealth: data.tele_health,
      };

      let status = ["doc_confirmed", "doc_completed", "doc_cancelled", "none"];
      let apptType;
      if (status.includes(data.pc_apptstatus)) {
        apptType = "Telehealth";
      } else {
        apptType = "Appointment";
      }

      state.appointmentData["provider"] = data.pc_aid;
      state.appointmentData["office"] = data.pc_facility;
      state.appointmentData["singleProvider"] = a;
      state.appointmentData["singleOffice"] = b;
      state.appointmentData["aptdate"] = data.eventDate;
      state.appointmentData["apttime"] = data.pc_startTime;
      state.appointmentData["showSelector"] = true;
      state.appointmentData["timeSelected"] = true;
      state.appointmentData["eid"] = data.pc_eid;
      state.appointmentData["reason"] = data.cancel_reason;
      state.appointmentData["speciality"] = data.speciality;
      state.appointmentData["appttype"] = apptType;
    },

    RescheduleAppointment: (state, action) => {
      let data = action.payload;
      let a = {
        // img_url: data.img_url,
        name: data.uname,
        // designation: data.title,
      };
      let b = {
        name: data.fac_name,
        facility_address: data.Address_of_Facility,
        telehealth: data.pc_telehealth,
      };

      let status = ["doc_confirmed", "doc_completed", "doc_cancelled", "none"];
      let apptType;
      if (status.includes(data.pc_apptstatus)) {
        apptType = "Telehealth";
      } else {
        apptType = "Appointment";
      }

      state.appointmentData["provider"] = data.pc_aid;
      state.appointmentData["office"] = data.pc_facility;
      state.appointmentData["singleProvider"] = a;
      state.appointmentData["singleOffice"] = b;
      // state.appointmentData["aptdate"] = data.eventDate;
      // state.appointmentData["apttime"] = data.pc_startTime;
      state.appointmentData["showSelector"] = true;
      // state.appointmentData["timeSelected"] = true;
      state.appointmentData["eid"] = data.pc_eid;
      state.appointmentData["reason"] = data.cancel_reason;
      state.appointmentData["speciality"] = data.speciality;
      state.appointmentData["appttype"] = apptType;
    },

    changeTime: (state, action) => {
      let newState = { ...state.appointmentData };
      newState["apttime"] = action.payload;
      newState["timeSelected"] = true;
      newState["showSelector"] = true;
      state.appointmentData = newState;
    },
    setProviderAvailablity: (state, action) => {
      state.providerAvailablity = action.payload;
      state.loadAvailablity = false;
    },
    getProviderAvailablity: (state, data) => {
      state.loadAvailablity = true;
    },
    getPortalAppAvailablity: (state, data) => {
      state.Loading = true;
    },
    setPortalAppAvailablity: (state, action) => {
      state.portalAppAvailablity = action.payload;
      state.Loading = false;
    },
    getOnsiteAppReq: (state, data) => {
      state.Loading = true;
    },
    setOnsiteAppReq: (state, action) => {
      state.OnsiteAppReq = action.payload;
      state.Loading = false;
    },
    GetBillingFacility: (Data) => {
      return Data;
    },
    setBillingFacility: (state, action) => {
      state.BillingFacility = action.payload;
    },
  },
});

export const {
  GetUpcomingAppointment,
  SetUpcomingAppointment,
  GetPastAppt,
  SetPastAppt,
  SetCancelAppt,
  GetCancelAppt,
  getProvidersList,
  setProvidersList,
  changeAppointmentData,
  clearInput,
  setFacilityList,
  getFacilityList,
  addAppointment,
  appointmentSaved,
  changeLoadingState,
  changeSlider,
  changeDropdown,
  clearAppointmentData,
  changeErrorObject,
  EditAppointment,
  AppointmentEdited,
  changeTime,
  setProviderAvailablity,
  getProviderAvailablity,
  setPortalAppAvailablity,
  getPortalAppAvailablity,
  getOnsiteAppReq,
  setOnsiteAppReq,
  GetBillingFacility,
  setBillingFacility,
  RescheduleAppointment,
} = AppointmentsSlice.actions;

export default AppointmentsSlice.reducer;
