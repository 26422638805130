import { all } from "redux-saga/effects";

import {
  PortalEnable,
  // ClientSaga,
  LoginEmailCheck,
  LoginPassWordSet,
  PasswordResetEmailCheck,
  SignUp,
  Logo,
  VzPortLogCheck,
  GetProviderStatus,
} from "./PreLogin/PreLoginWatchers";

import { LoginSaga } from "./Login/LoginSagaWatchers";

import {
  UserDetails,
  NotificationDetails,
  NotificationUpdate,
  NotificationDelete,
} from "./TopNavbar/TopNavbarSagaWatchers";

import {
  PayBalSaga,
  Medications,
  WelcomePop,
  FirstLogin,
  FirstUserLogin,
} from "./DashBoard/DashboardSagaWatchers";

import {
  upcomingAppointmentsSaga,
  getProviderAvailablitySaga,
  EditAppointmentSaga,
  GetProviderSaga,
  GetFacilitySaga,
  SaveAppointmentSaga,
  CancelApptSaga,
  PastApptSaga,
  getPortalAppAvailablitySaga,
  getOnsiteAppReqSaga,
  BillingFacilitySaga,
} from "./Appointments/AppointmentsSagaWatchers";
import {
  CardDetails,
  AddCardDetail,
  EditCardDetail,
  RemoveCardDetails,
  InvoiceList,
  OneInvoiceList,
  MakePayment,
  PaymentHistory,
} from "./Billing/BillingSagaWatchers";
import {
  DocumentLists,
  InsertDocuments,
  UpdateDocument,
  DeleteDocument,
  DownloadDocument,
  PreViewDocument,
  SignDocument,
  DeleteTempDocument,
  DeleteLabDocument,
  DownloadCreadential,
  DownloadLabCreadential,
  ViewEditDocument,
  FirstSaveDocument,
} from "./Documents/DocumentsSagaWatchers";
import { ListSaga } from "./HealthProfile/HealthProfileSagaWatchers";

import {
  ProviderListSaga,
  MessagerListSaga,
  MessageSendSaga,
  PreviewDownloadSagaM,
  UnreadCountUpdateSaga,
  PinchatSaga,
} from "./Messenger/MessengerSagaWatchers";
import {
  PatGenderDetails,
  PatMaritalStatus,
  PatLangDetails,
  PatRaceDetails,
  PatientProfileUpdate,
  PatientProfileRemove,
  PatientDetailUpdate,
  EmailExist,
  ProfileUpdateStatus,
  PatientStatus,
  ResetPassword,
  CheckPatPortalPassword,
} from "./Settings/SettingsSagaWatchers";

export default function* rootSaga() {
  return yield all([
    //PreLogin
    PortalEnable(),
    // ClientSaga(),
    LoginEmailCheck(),
    LoginPassWordSet(),
    PasswordResetEmailCheck(),
    SignUp(),
    Logo(),
    VzPortLogCheck(),
    //PreLogin

    //TopNavbar //
    UserDetails(),
    NotificationDetails(),
    NotificationUpdate(),
    NotificationDelete(),
    //TopNavbar //

    // DashBoard //
    PayBalSaga(),
    Medications(),
    // DashBoard //

    // Appointments//
    PastApptSaga(),
    upcomingAppointmentsSaga(),
    CancelApptSaga(),
    getProviderAvailablitySaga(),
    EditAppointmentSaga(),
    GetProviderSaga(),
    GetFacilitySaga(),
    SaveAppointmentSaga(),
    getPortalAppAvailablitySaga(),
    getOnsiteAppReqSaga(),
    BillingFacilitySaga(),
    // Appointments//

    // Login //
    LoginSaga(),
    WelcomePop(),
    FirstLogin(),
    FirstUserLogin(),
    // Login //

    // Billing //
    CardDetails(),
    AddCardDetail(),
    EditCardDetail(),
    RemoveCardDetails(),
    InvoiceList(),
    OneInvoiceList(),
    MakePayment(),
    PaymentHistory(),
    // Billing //

    // Documents //
    DocumentLists(),
    InsertDocuments(),
    UpdateDocument(),
    DeleteDocument(),
    DownloadDocument(),
    PreViewDocument(),
    SignDocument(),
    DeleteTempDocument(),
    DeleteLabDocument(),
    DownloadCreadential(),
    DownloadLabCreadential(),
    ViewEditDocument(),
    FirstSaveDocument(),
    // Documents //

    // Health Profile //
    ListSaga(),

    // Health Profile //

    // Home Work //
    // Home Work //

    // Message //
    ProviderListSaga(),
    MessagerListSaga(),
    MessageSendSaga(),
    PreviewDownloadSagaM(),
    UnreadCountUpdateSaga(),
    GetProviderStatus(),
    PinchatSaga(),
    // Message //

    // Settings //
    PatGenderDetails(),
    PatMaritalStatus(),
    PatLangDetails(),
    PatRaceDetails(),
    PatientProfileUpdate(),
    PatientProfileRemove(),
    PatientDetailUpdate(),
    EmailExist(),
    ProfileUpdateStatus(),
    PatientStatus(),
    ResetPassword(),
    CheckPatPortalPassword(),
    // Settings //
  ]);
}
