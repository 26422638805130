import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  changeAppointmentData,
  GetUpcomingAppointment,
  GetPastAppt,
  getProviderAvailablity,
} from "../../StateManagement/Reducers/AppointmentsState";

import PastAppointment from "./PastAppointment";
import TopNav from "../TopNav";
import AddAppointment from "./AddAppointment";
import UpcomingAppointments from "./UpcomingAppointments";
import MessageModal from "./AddAppointment/MessageModal";
import Loading from "../StyledComponents/Loading";

import { Div, BodyContainer, PastApptBodycont } from "../StyledComponents";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const Appointment = () => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //

  const dispatch = useDispatch();
  const params = useParams();
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");
  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );
  const addLoader = useSelector((state) => state.Appointments.loadingSave);
  const upLoader = useSelector((state) => state.Appointments.loadCounter);
  const pastLoader = useSelector((state) => state.Appointments.pastApptLoading);
  const refresh = useSelector((state) => state.Dashboard.RefreshAppt);

  // ------------------------------ State and Variables End--------------------------------------------------- //

  useEffect(() => {
    dispatch(GetUpcomingAppointment("show"));
  }, [refresh, params.selector]);

  useEffect(() => {
    dispatch(GetPastAppt());
  }, [params.selector]);

  useEffect(() => {
    dispatch(
      getProviderAvailablity({
        provider_id: appointmentData["provider"],
        facility_id: appointmentData["office"],
        date: appointmentData["aptdate"],
        pid: Pat_ID,
      })
    );
  }, [
    dispatch,
    appointmentData["provider"],
    appointmentData["aptdate"],
    appointmentData["office"],
  ]);

  return (
    <>
      <TopNav
        title="Appointment List"
        TitleDescription="Manage Your Appointment"
      />
      {addLoader || upLoader || pastLoader ? (
        <>
          <Loading />
        </>
      ) : (
        <PastApptBodycont display="flex" height="calc(100% - 111px)">
          <Div width="calc(37% + 1px)" height="100%">
            <UpcomingAppointments />
          </Div>

          <Div height="100%" width="63%">
            {params.selector === "add_appointment" ? (
              <AddAppointment />
            ) : (
              <PastAppointment />
            )}
          </Div>
        </PastApptBodycont>
      )}

      <MessageModal
        active={appointmentData["showMessage"]}
        color={appointmentData["messageType"]}
        onHide={() =>
          dispatch(changeAppointmentData({ name: "showMessage", value: false }))
        }
      />
    </>
  );
};

export default Appointment;
