import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GetEmailRes } from "../../../StateManagement/Reducers/PreLoginState";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import LoginLeft from "../LoginLeft";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Description,
} from "../../StyledComponents";
import {
  Container,
  Input,
  TitleImage,
  Title,
  ImageContainer,
} from "../StyledComponents";

import MailImage from "../../../Assets/images/mailPortal.png";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const InstantSignIn = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  var params = useParams();

  const [email, setemail] = useState("");
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [btndis, setbtndis] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  let Mode = useSelector((state) => state.PreLogin.mode);
  const Mode_alt =
    localStorage.getItem("Mode") &&
    Decrypt_Value(localStorage.getItem("Mode"), "vozoportal");
  const text = useSelector((state) => state.PreLogin.logo);
  const emailResponse = useSelector((state) => state.PreLogin.EmailResp);

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (firstDot[0] === "client") {
    DBhost = "app";
  } else if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const HandleSubmit = (e) => {
    e.preventDefault();
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);

    // let mode = Mode
    //   ? Mode
    //   : localStorage.getItem("Mode")
    //   ? Decrypt_Value(localStorage.getItem("Mode"), "vozoportal")
    //   : "old";
    let mode = params.OldOrNew === "Old" ? "old" : "new";
    localStorage.setItem("Mode", Encrypt_Value(mode, "vozoportal"));
    if (email !== "") {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (regex.test(email) == true) {
        dispatch(GetEmailRes({ type: { email: email }, mode: mode }));
        setModalAlerShow(true);
        setStatus("success");
        setModalMsg("Your details are being verified.");
      } else {
        setMailErr(true);
        setErrMsg("Invalid Email");
      }
    } else {
      setMailErr(true);
      setErrMsg("Please Enter the Email Address");
    }
  };

  const EmailCheck = (e) => {
    setemail(e.target.value);
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regex.test(e.target.value)) {
      setMailErr(false);
    } else {
      setMailErr(true);
      setErrMsg("Invalid Email");
    }
  };

  useEffect(() => {
    let mode = Mode !== "" ? Mode : Mode_alt;
    if (emailResponse.status === "Success") {
      setModalAlerShow(false);
      if (emailResponse.output === "Account Already Found!") {
        if (mode === "new") {
          Navigate("/InstantSignIn/New");
        } else {
          Navigate("/Login");
        }
      } else {
        Navigate("/VerifyAccount", {
          state: { type: { email } },
        });
      }
    } else if (emailResponse.status === "Failure") {
      setMailErr(true);
      setModalAlerShow(false);
      if (mode === "old") {
        setErrMsg("Please Enter Registered Email");
      } else if (mode === "new") {
        setErrMsg("This Email already exists");
      }
    }
  }, [emailResponse]);

  useEffect(() => {
    setErrMsg("");
  }, []);

  return (
    <Container Flex height="100vh" minWidth="1024px">
      <Div width="50%" backColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backColor="#FFFFFF">
        <Div width="400px" textAlign="center" margin="auto">
          {text.length !== 0 && (
            <ImageContainer marginTop="72px" marginBottom="65px">
              <Image
                loading="lazy"
                src={File_URL + text}
                height="41px"
                width="173px"
              />
            </ImageContainer>
          )}
          <TitleImage
            src={MailImage}
            alt="Email Verified"
            width="124px"
            height="124px"
            marginTop="10%"
          />
          <Title
            color="#000000"
            fontWeight="800"
            fontSize="32px"
            lineHeight="44px"
            marginBottom="12px"
          >
            Enter Email
          </Title>
          <Description
            fontSize="20px"
            lineHeight="27px"
            alignItems="center"
            color=" #667085"
            marginBottom="32px"
          >
            Enter email id to get your verification code
          </Description>
          <Form>
            <FormGroup marginBottom="41px" display="grid" width="100%">
              <FormLabel
                marginBottom="6px"
                textAlign="left"
                fontSize="14px"
                color="#7D8592"
                fontWeight="600"
                lineHeight="24px"
              >
                Email address
              </FormLabel>
              <Input
                color="#7D8592"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => EmailCheck(e)}
                id="sig_emil-inp_ent"
              />
              {mailErr && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  marginTop="5px"
                  fontFamily="inter"
                >
                  {errMsg}
                </FormLabel>
              )}
            </FormGroup>
            <FormGroup marginBottom="32px" display="grid" width="100%">
              <Button
                background="#2C7BE5"
                hoverBackColor="#005FB2"
                className="save_active"
                padding="18px 140px 18px 140px"
                fontWeight="600"
                fontSize="16px"
                lineHeight="22px"
                letterSpacing="1.1px"
                disabled={btndis}
                onClick={(e) => HandleSubmit(e)}
                id="sig_sub-btn_cnt"
              >
                Continue
              </Button>
            </FormGroup>
          </Form>
        </Div>
      </Div>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Container>
  );
};

export default InstantSignIn;
