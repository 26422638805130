import axiosInst from "../../Config";

export const PortalEnable_Api = async (data) =>
  await axiosInst.get(`/vozoportal/PortalEnableCheck`);

// export const Client_Api = async (data) => await axiosInst.get(`/vozo/transfer`);

export const LoginEmailCheck_Api = async (data) =>
  await axiosInst.post(`/vozoportal/sendverificationemail`, data);

export const LoginEmailCheckNew_Api = async (data) =>
  await axiosInst.post(`/vozoportal/sendverificationemailfornewpatient`, data);

export const PasswordResetEmailCheck_Api = async (data) =>
  await axiosInst.post(`/vozoportal/sendverificationemailforreset`, data);

export const LoginPasswordSet_Api = async (data) =>
  await axiosInst.post(`/vozoportal/setpatientpassword`, data);

export const SignUp_Api = async (data) =>
  await axiosInst.post(`/vozoportal/signup`, data);

export const VzPortalApi = async (data) =>
  await axiosInst.get(
    `/vozoportal/VzPortalLoginCheck?pid=${data.pid}&uid=${data.uid}&type=${data.type}`
  );

export const CheckProvStatusApi = async (data) =>
  await axiosInst.get(`/vozoportal/getProvChatStatus?uid=${data}`);
