import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";

import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

import {
  getProvidersList,
  changeAppointmentData,
  getFacilityList,
  addAppointment,
  changeLoadingState,
  changeErrorObject,
  AppointmentEdited,
  clearInput,
  GetBillingFacility,
  appointmentSaved,
} from "../../../StateManagement/Reducers/AppointmentsState";

import SelectedProvider from "./SelectedProvider";
import SelectedOffice from "./SelectedOffice";
import TimeDateSelector from "./TimeDateSelector";

import { BsSearch } from "react-icons/bs";
import {
  Div,
  Text,
  NormalImage,
  Button,
  SpanCont,
} from "../../StyledComponents";
import {
  InputInlineText,
  ReasonInput,
  DropdownDiv,
  AppointmentInput,
  DropdownText,
  AppointmentInput2,
  AppointmentInput3,
  TimeDateDiv,
  DivVariant2,
  HiddenButton,
  InitialsText,
  Text1,
  ChangeText,
} from "./Styles";

import dateIcon from "../../../Assets/images/calendar_icon.png";
import providerIcon from "../../../Assets/images/provider_icon.png";
import specialityIcon from "../../../Assets/images/speciality.png";
import appointmentIcon from "../../../Assets/images/appointment_icon.png";
import clockLogo from "../../../Assets/images/clock_logo.png";
import officeIcon from "../../../Assets/images/office_icon.png";
import officeLogo from "../../../Assets/images/office_logo.png";
import providerLogo from "../../../Assets/images/pro_icon1.png";
import specialityLogo from "../../../Assets/images/speciality_logo.png";
import appointmentLogo from "../../../Assets/images/appointment_logo.png";
import reasonIcon from "../../../Assets/images/reason_icon.png";
import facility_icon from "../../../Assets/images/facility_icon.png";
import tele_icon from "../../../Assets/images/telehealth_icon.png";
import { TextVariant } from "./StyledComponents";

const AddAppointment = () => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //
  const location = useLocation();
  const { data } = location.state || {};

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const providerRef = useRef(null);
  const officeRef = useRef(null);
  const specialityRef = useRef(null);
  const appointmentRef = useRef(null);
  const timeRef = useRef(null);
  const timeDivRef = useRef(null);
  const timeSelectRef = useRef(null);
  const dropRef1 = useRef(null);
  const dropRef2 = useRef(null);
  const dropRef3 = useRef(null);
  const dropRef4 = useRef(null);

  const [providerError, setProviderError] = useState(false);
  const [specialityError, setSpecialityError] = useState(false);
  const [appointmentError, setAppointmentError] = useState(false);
  const [officeError, setOfficeError] = useState(false);
  const [providerDrop, setProviderDrop] = useState(false);
  const [officeDrop, setOfficeDrop] = useState(false);
  const [searchProvider, setSearchProvider] = useState("");
  const [searchOffice, setSearchOffice] = useState("");
  const [searchSpeciality, setSearchSpeciality] = useState("");
  const [searchAppointment, setSearchAppointment] = useState("");
  const [imageError, setImageError] = useState([]);
  const [savebtndisabled, setsavebtndisabled] = useState(false);
  const [specialityDrop, setSpecialityDrop] = useState(false);
  const [appointmentDrop, setAppointmentDrop] = useState(false);

  const bill_facility_id = useSelector(
    (state) => state.Appointments.BillingFacility
  );
  const providersList = useSelector(
    (state) => state.Appointments.providersList
  );
  const facilityList = useSelector((state) => state.Appointments.facilityList);
  const providerLoading = useSelector(
    (state) => state.Appointments.loadingProviders
  );
  const officeLoading = useSelector(
    (state) => state.Appointments.loadingOffice
  );
  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );
  const timeError = useSelector((state) => state.Appointments.timeError);

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (firstDot[0] === "client") {
    DBhost = "app";
  } else if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const proImageUrl = backurl + "/sites/" + DBhost + "/documentimg/";
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

  // ------------------------------ State and Variables End--------------------------------------------------- //

  // ------------------------------ Functions Start------------------------------------------------------------- //

  const convertHMS = (value) => {
    return Math.floor(value * 60);
  };

  const filterSpecialities = (searchTerm) => {
    return specialityList.filter((speciality) =>
      speciality.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const filterappointments = (searchTerm) => {
    return appointmentList.filter((appointment) =>
      appointment.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const selectProvider = (row) => {
    dispatch(changeAppointmentData({ name: "select_provider", value: row }));
    setProviderError(false);
    setProviderDrop(false);
  };

  const selectOffice = (row) => {
    dispatch(changeAppointmentData({ name: "select_office", value: row }));
    setOfficeError(false);
    setOfficeDrop(false);
  };

  const speciality = (row) => {
    dispatch(changeAppointmentData({ name: "speciality", value: row.name }));
    setSpecialityError(false);
    setSpecialityDrop(false);
  };

  const Appointment_Type = (row) => {
    dispatch(changeAppointmentData({ name: "appttype", value: row.name }));
    setAppointmentError(false);
    setAppointmentDrop(false);
  };

  const handleClick1 = (e) => {
    if (
      providerRef.current &&
      !providerRef.current.contains(e.target) &&
      e.target.name !== "submit_button"
    ) {
      setSearchProvider("");
    }
  };

  const handleClick2 = (e) => {
    if (
      officeRef.current &&
      !officeRef.current.contains(e.target) &&
      e.target.name !== "submit_button"
    ) {
      setSearchOffice("");
    }
  };

  const handleClick3 = (e) => {
    if (
      timeDivRef.current &&
      timeSelectRef.current &&
      !timeDivRef.current.contains(e.target) &&
      !timeSelectRef.current.contains(e.target)
    ) {
      dispatch(changeAppointmentData({ name: "showSelector", value: true }));
    }
  };

  const handleClick4 = (e) => {
    if (
      dropRef1.current &&
      providerRef.current &&
      !dropRef1.current.contains(e.target) &&
      !providerRef.current.contains(e.target)
    ) {
      setProviderDrop(false);
    }
  };

  const handleClick5 = (e) => {
    if (
      dropRef2.current &&
      officeRef.current &&
      !dropRef2.current.contains(e.target) &&
      !officeRef.current.contains(e.target)
    ) {
      setOfficeDrop(false);
    }
  };
  const handleClick6 = (e) => {
    if (
      specialityRef.current &&
      !specialityRef.current.contains(e.target) &&
      e.target.name !== "submit_button"
    ) {
      setSearchSpeciality("");
    }
  };
  const handleClick7 = (e) => {
    if (
      dropRef3.current &&
      specialityRef.current &&
      !dropRef3.current.contains(e.target) &&
      !specialityRef.current.contains(e.target)
    ) {
      setSpecialityDrop(false);
    }
  };
  const handleClick8 = (e) => {
    if (
      appointmentRef.current &&
      !appointmentRef.current.contains(e.target) &&
      e.target.name !== "submit_button"
    ) {
      setSearchAppointment("");
    }
  };
  const handleClick9 = (e) => {
    if (
      dropRef4.current &&
      appointmentRef.current &&
      !dropRef4.current.contains(e.target) &&
      !appointmentRef.current.contains(e.target)
    ) {
      setAppointmentDrop(false);
    }
  };
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getInitials = (x) => {
    let a = x.split(" ");
    let b = a[0].substr(0, 1).toUpperCase();
    return b;
  };
  const specialityList = [
    { id: 1, value: "Plastic Surgery", name: "Plastic Surgery" },
    { id: 2, value: "Dermatology", name: "Dermatology" },
    { id: 3, value: "Sleep Medicine", name: "Sleep Medicine" },
    { id: 4, value: "Allergy & Immunology", name: "Allergy & Immunology" },
    { id: 5, value: "Concierge Medicine", name: "Concierge Medicine" },
    { id: 6, value: "Oncology", name: "Oncology" },
    { id: 7, value: "Palliative Care", name: "Palliative Care" },
    { id: 8, value: "Endocrinology", name: "Endocrinology" },
    { id: 9, value: "Sports Medicine", name: "Sports Medicine" },
    { id: 10, value: "Endocrinology", name: "Endocrinology" },
    { id: 11, value: "Hospice", name: "Hospice" },
    { id: 12, value: "Pediatrics", name: "Pediatrics" },
    { id: 13, value: "Pathology", name: "Pathology" },
    {
      id: 14,
      value: "Obstetrics and Gynecology",
      name: "Obstetrics and Gynecology",
    },
    { id: 15, value: "Hand Surgery", name: "Hand Surgery" },
    { id: 16, value: "Hematology", name: "Hematology" },
    { id: 17, value: "Emergency Medicine", name: "Emergency Medicine" },
    { id: 18, value: "Surgery", name: "Surgery" },
    { id: 19, value: "Cardiology", name: "Cardiology" },
    { id: 20, value: "Vascular Surgery", name: "Vascular Surgery" },
    { id: 21, value: "Pulmonology", name: "Pulmonology" },
    { id: 22, value: "Radiology", name: "Radiology" },
    { id: 23, value: "ECG Management", name: "ECG Management" },
    {
      id: 24,
      value: "Physical Medicine & Rehabilitation",
      name: "Physical Medicine & Rehabilitation",
    },
    { id: 25, value: "Ophthalmology", name: "Ophthalmology" },
    { id: 26, value: "Geriatric Medicine", name: "Geriatric Medicine" },
    { id: 27, value: "Neurology", name: "Neurology" },
    { id: 28, value: "Infectious Disease", name: "Infectious Disease" },
    { id: 29, value: "Nephrology", name: "Nephrology" },
    {
      id: 30,
      value: "Ambulatory Care & Surgery",
      name: "Ambulatory Care & Surgery",
    },
    { id: 31, value: "Otolaryngology (ENT)", name: "Otolaryngology (ENT)" },
    { id: 32, value: "Dental Health", name: "Dental Health" },
    { id: 33, value: "Chiropractic", name: "Chiropractic" },
    { id: 34, value: "Psychiatry", name: "Psychiatry" },
    { id: 35, value: "Urology", name: "Urology" },
    { id: 36, value: "Wound Care", name: "Wound Care" },
    { id: 37, value: "Orthopedic Surgery", name: "Orthopedic Surgery" },
    { id: 38, value: "Behavior Health", name: "Behavior Health" },
    { id: 39, value: "Gastroenterology", name: "Gastroenterology" },
    { id: 40, value: "Anesthesiology", name: "Anesthesiology" },
    { id: 41, value: "Family Medicine", name: "Family Medicine" },
    { id: 42, value: "Podiatry", name: "Podiatry" },
    { id: 43, value: "Austism", name: "Austism" },
    { id: 44, value: "Rheumatology", name: "Rheumatology" },
  ];

  const appointmentList = [
    { id: 1, name: "Appointment" },
    { id: 2, name: "Telehealth" },
  ];

  const errorImage = (id) => {
    setImageError([id, ...imageError]);
  };
  const saveAppointment = () => {
    setsavebtndisabled(true);
    setTimeout(() => {
      setsavebtndisabled(false);
    }, 1200);

    if (bill_facility_id === undefined) {
      dispatch(
        appointmentSaved({
          color: "danger",
          message:
            "unable to book appointments as there is no billing facility provided.\nContact provider for more information",
        })
      );
      return;
    }
    var err = 0;
    if (appointmentData["provider"] === "") {
      setProviderError(true);
      err = 2;
    }
    if (appointmentData["office"] === "") {
      setOfficeError(true);
      err = 2;
    }
    if (appointmentData["speciality"] === "") {
      setSpecialityError(true);
      err = 2;
    }
    if (appointmentData["appttype"] === "") {
      setAppointmentError(true);
      err = 2;
    }
    if (appointmentData["apttime"] === "") {
      dispatch(changeErrorObject({ name: "noTime", value: true }));
      err = 2;
    }

    if (err > 0) {
      return false;
    } else {
      var timenew =
        appointmentData["aptdate"] + " " + appointmentData["apttime"];

      var timedetnew = moment(timenew, "YYYY-MM-DD hh:mm a");
      var durationnew = 15;

      if (appointmentData["appttype"] === "Appointment") {
        var appttypenew = 1,
          categoryId = 2,
          pc_title = "New Patient",
          pc_apptstatus = "EMAIL";
      } else {
        appttypenew = 2;
        categoryId = 16;
        pc_title = "Tele Health";
        pc_apptstatus = "doc_confirmed";
      }

      var alldaynew = false;
      var walkinnew = false;
      var repeats = "";
      var select1 = 0;
      var select2 = 0;
      var untildate = "";
      var fromdate = appointmentData["aptdate"];
      var todate = appointmentData["aptdate"];
      var days_wise = false;

      var tempdatenew = moment(timedetnew, moment.defaultFormat).toDate();
      var startTimenew = moment(tempdatenew).format("hh:mm a");
      var returned_endatenew = moment(tempdatenew, "YYYY-MM-DD hh:mm a").add(
        durationnew,
        "minutes"
      );
      var startdatenew = moment(tempdatenew).format("YYYY-MM-DD");
      var secondsnew = convertHMS(durationnew);
      var endTimenew = moment(returned_endatenew).format("hh:mm a");
      var endDatenew = moment(returned_endatenew).format("YYYY-MM-DD");

      var article = {
        pc_pid: Pat_ID,
        pc_catid: categoryId,
        pc_aid: appointmentData["provider"],
        pc_title,
        pc_duration: secondsnew,
        pc_hometext: "",
        pc_apptstatus,
        pc_eventDate: startdatenew,
        pc_endDate: endDatenew,
        pc_startTime: startTimenew,
        pc_endTime: endTimenew,
        pc_facility: appointmentData["office"],
        pc_informant: appointmentData["provider"],
        pc_billing_location: "",
        pc_select1: select1,
        pc_select2: select2,
        pc_untildate: untildate,
        pc_fromdate: fromdate,
        pc_todate: todate,
        days_wise: days_wise,
        allday: alldaynew,
        repeats: repeats,
        appttype: appttypenew,
        walkin: walkinnew,
        encounter: appointmentData["encounter"],
        eid: appointmentData["eid"],
        patient_reason: appointmentData["reason"],
        speciality: appointmentData["speciality"],
      };
      if (appointmentData["eid"] === 0) {
        dispatch(addAppointment(article));
      } else {
        dispatch(AppointmentEdited(article));
      }
    }
  };

  // ------------------------------ Functions End------------------------------------------------------------- //

  // ------------------------------ useEffect Start------------------------------------------------------------- //

  //provider search
  useEffect(() => {
    let isCancelled = false;

    const handlechange = async () => {
      if (appointmentData["office"] !== "") {
        dispatch(changeLoadingState({ name: "loadProvider", value: true }));
        await timeout(1000);
        if (!isCancelled) {
          dispatch(
            getProvidersList({
              name: searchProvider,
              facility_id: appointmentData["office"],
              pid: Pat_ID,
              selectSpeciality: appointmentData["speciality"],
              appttype: appointmentData["appttype"],
            })
          );
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [
    dispatch,
    searchProvider,
    appointmentData["office"],
    Pat_ID,
    appointmentData["speciality"],
    appointmentData["appttype"],
  ]);

  //office search
  useEffect(() => {
    let isCancelled = false;

    const handlechange = async () => {
      dispatch(changeLoadingState({ name: "loadOffice", value: true }));
      await timeout(1000);
      if (!isCancelled) {
        dispatch(getFacilityList({ find: searchOffice }));
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [dispatch, searchOffice]);

  useEffect(() => {
    document.addEventListener("click", handleClick4);
    document.addEventListener("click", handleClick5);
    document.addEventListener("click", handleClick7);
    document.addEventListener("click", handleClick9);
    document.addEventListener("mouseup", handleClick1);
    document.addEventListener("mouseup", handleClick2);
    document.addEventListener("mouseup", handleClick3);
    document.addEventListener("mouseup", handleClick6);
    document.addEventListener("mouseup", handleClick8);

    return () => {
      document.removeEventListener("click", handleClick4);
      document.removeEventListener("click", handleClick5);
      document.removeEventListener("click", handleClick7);
      document.removeEventListener("click", handleClick9);
      document.removeEventListener("mouseup", handleClick1);
      document.removeEventListener("mouseup", handleClick2);
      document.removeEventListener("mouseup", handleClick3);
      document.removeEventListener("mouseup", handleClick6);
      document.removeEventListener("mouseup", handleClick8);
    };
  }, []);

  useEffect(() => {
    if (appointmentData["changeUrl"].length > 0) {
      navigation(`${appointmentData["changeUrl"]}`);
    }
  }, [navigation, appointmentData["changeUrl"]]);

  useEffect(() => {
    dispatch(GetBillingFacility());
  }, []);

  // ------------------------------ useEffect End------------------------------------------------------------- //

  return (
    <Div
      backColor="#f4f4f4"
      width="100%"
      height="100%"
      overflowY="auto"
      flexCenterHorizontal
    >
      <DivVariant2>
        <Text1 align="left" fontWeight="500" className="createfont">
          {appointmentData["eid"] !== 0
            ? "Edit Appointment"
            : "Create new appointment"}
        </Text1>

        <Div
          margin="20px 0px 0px 0px"
          flexCenterBetween
          height={appointmentData["office"] === "" ? "100px" : "auto"}
        >
          <Div flexCenterVertical>
            <NormalImage
              loading="lazy"
              src={officeIcon}
              height="13.33px"
              width="14.67px"
            />
            <Text
              fontSize="13px"
              fontWeight="400"
              width="auto"
              margin="0px 0px 0px 12px"
            >
              Office
            </Text>
            {/* {appointmentData["office"] !== "" && (
              <HiddenButton
                mediaWidth="1300px"
                color="#407BFF"
                fontSize="12px"
                marginLeft="12px"
                background="white"
                cursor="pointer"
                onClick={() => dispatch(clearInput("office"))}
              >
                Edit
              </HiddenButton>
            )} */}
          </Div>
          <Div width="55%" height="100%" position="relative">
            {appointmentData["office"] !== "" ? (
              <TextVariant width="100%" align="center">
                <SelectedOffice Type="office" RescheduleData={data} />
              </TextVariant>
            ) : (
              <>
                <AppointmentInput2
                  autoComplete="off"
                  errorBorder={officeError}
                  name="office"
                  backgroundImage={officeLogo}
                  placeholder="Type a customer name"
                  ref={officeRef}
                  value={searchOffice}
                  onChange={(e) => {
                    setSearchOffice(e.target.value);
                  }}
                  dropOpen={officeDrop}
                  onClick={() => setOfficeDrop(true)}
                  onFocus={() => setOfficeDrop(true)}
                  id="aptLst_napt-inp_ofc"
                />
                <BsSearch id="inFocus1" />
                {officeError && (
                  <Text
                    color="#F65160"
                    margin="2px 0px 0px 0px"
                    fontSize="12px"
                    width="100%"
                    align="center"
                  >
                    Select an office
                  </Text>
                )}

                <InputInlineText onClick={() => officeRef.current.focus()}>
                  Select Office
                </InputInlineText>
                {officeDrop && (
                  <DropdownDiv
                    width="100%"
                    id="inFocus2"
                    ref={dropRef2}
                    onClick={() => setOfficeDrop(false)}
                  >
                    {officeLoading ? (
                      <DropdownText
                        width="100%"
                        border="none"
                        padding="0 16px"
                        flexCenterVertical
                      >
                        Loading...
                      </DropdownText>
                    ) : (
                      <>
                        {facilityList.length !== 0 ? (
                          <>
                            {facilityList.map((row) => (
                              <DropdownText
                                width="100%"
                                border="none"
                                height="50px"
                                flexCenterVertical
                                name="office"
                                paddingLeft="16px"
                                key={row.id}
                                onClick={(e) => selectOffice(row)}
                              >
                                {row.name}
                              </DropdownText>
                            ))}
                          </>
                        ) : (
                          <DropdownText
                            width="100%"
                            border="none"
                            flexCenterVertical
                            padding="0 16px"
                          >
                            No Results Found
                          </DropdownText>
                        )}
                      </>
                    )}
                  </DropdownDiv>
                )}
              </>
            )}
          </Div>
        </Div>

        {/* speciality */}

        <Div
          margin="20px 0 0"
          flexCenterBetween
          height={appointmentData["speciality"] === "" ? "100px" : "auto"}
        >
          <Div flexCenterVertical>
            <NormalImage loading="lazy" src={specialityIcon} />
            <Text
              fontSize="13px"
              fontWeight="400"
              width="auto"
              margin="0px 0px 0px 12px"
            >
              Speciality
            </Text>
            {/* {appointmentData["speciality"] !== "" && (
              <HiddenButton
                mediaWidth="1100px"
                color="#407BFF"
                fontSize="12px"
                marginLeft="12px"
                background="white"
                cursor="pointer"
                onClick={() => {
                  dispatch(
                    changeAppointmentData({ name: "speciality", value: "" })
                  );
                  setSpecialityDrop(true);
                  dispatch(clearInput("provider"));
                }}
              >
                Edit
              </HiddenButton>
            )} */}
          </Div>
          <Div width="55%" height="100%" position="relative">
            {appointmentData["speciality"] !== "" ? (
              <Div
                border="1px solid #407BFF"
                borderRadius="6px"
                width="100%"
                padding="12px 16px"
              >
                <ChangeText
                  mediaWidth="1100px"
                  absolute
                  onClick={() => {
                    dispatch(
                      changeAppointmentData({ name: "speciality", value: "" })
                    );
                    setSpecialityDrop(true);
                    dispatch(clearInput("provider"));
                  }}
                >
                  Change
                </ChangeText>
                <TextVariant width="100%" align="center">
                  {appointmentData["speciality"]}
                </TextVariant>
              </Div>
            ) : (
              <>
                <AppointmentInput2
                  errorBorder={specialityError}
                  name="speciality"
                  backgroundImage={specialityLogo}
                  placeholder="Type a speciality"
                  ref={specialityRef}
                  value={searchSpeciality}
                  onChange={(e) => {
                    setSearchSpeciality(e.target.value);
                  }}
                  dropOpen={specialityDrop}
                  onClick={() => setSpecialityDrop(true)}
                  onFocus={() => setSpecialityDrop(true)}
                  id="aptLst_napt-inp_special"
                />
                <BsSearch id="inFocus1" />
                {specialityError && (
                  <Text
                    color="#F65160"
                    margin="2px 0px 0px 0px"
                    fontSize="12px"
                    width="100%"
                    align="center"
                  >
                    Select a Speciality
                  </Text>
                )}

                <InputInlineText onClick={() => specialityRef.current.focus()}>
                  Select Speciality
                </InputInlineText>
                {specialityDrop && (
                  <DropdownDiv
                    width="100%"
                    id="inFocus2"
                    ref={dropRef3}
                    onClick={() => setSpecialityDrop(false)}
                    style={{ overflowX: "hidden" }}
                  >
                    {filterSpecialities(searchSpeciality).length !== 0 ? (
                      <>
                        {filterSpecialities(searchSpeciality).map((row) => (
                          <DropdownText
                            width="100%"
                            border="none"
                            height="32px"
                            padding=" 0 0 0 18px"
                            flexCenterVertical
                            name="speciality"
                            key={row.id}
                            onClick={(e) => speciality(row)}
                          >
                            {row.name}
                          </DropdownText>
                        ))}
                      </>
                    ) : (
                      <DropdownText
                        width="100%"
                        border="none"
                        flexCenterVertical
                        padding="0 16px"
                      >
                        No Results Found
                      </DropdownText>
                    )}
                  </DropdownDiv>
                )}
              </>
            )}
          </Div>
        </Div>

        {/* Speciality End */}

        {/* Appointment type Start */}

        <Div
          margin="20px 0 0"
          flexCenterBetween
          height={appointmentData["appttype"] === "" ? "100px" : "auto"}
        >
          <Div flexCenterVertical>
            <NormalImage loading="lazy" src={appointmentIcon} />
            <Text
              fontSize="13px"
              fontWeight="400"
              width="auto"
              margin="0px 0px 0px 12px"
            >
              Appointment
            </Text>
            {/* {appointmentData["appttype"] !== "" && (
              <HiddenButton
                mediaWidth="1100px"
                color="#407BFF"
                fontSize="12px"
                marginLeft="12px"
                background="white"
                cursor="pointer"
                onClick={() => {
                  dispatch(
                    changeAppointmentData({ name: "appttype", value: "" })
                  );
                  setAppointmentDrop("");
                  dispatch(clearInput("provider"));
                }}
              >
                Edit
              </HiddenButton>
            )} */}
          </Div>
          <Div width="55%" height="100%" position="relative">
            {appointmentData["appttype"] !== "" ? (
              <Div
                border="1px solid #407BFF"
                borderRadius="6px"
                width="100%"
                padding="12px 16px"
              >
                <ChangeText
                  mediaWidth="1100px"
                  absolute
                  onClick={() => {
                    dispatch(
                      changeAppointmentData({ name: "appttype", value: "" })
                    );
                    dispatch(clearInput("provider"));
                  }}
                >
                  Change
                </ChangeText>
                <TextVariant width="100%" align="center">
                  {appointmentData["appttype"]}
                </TextVariant>
              </Div>
            ) : (
              <>
                <AppointmentInput2
                  autoComplete="off"
                  errorBorder={appointmentError}
                  name="appointment"
                  backgroundImage={appointmentLogo}
                  placeholder="Select Appointment type"
                  ref={appointmentRef}
                  value={searchAppointment}
                  onChange={(e) => {
                    setSearchAppointment(e.target.value);
                  }}
                  dropOpen={appointmentDrop}
                  onClick={() => setAppointmentDrop(true)}
                  onFocus={() => setAppointmentDrop(true)}
                  id="aptLst_napt-inp_appointment"
                />
                <BsSearch id="inFocus1" />
                {appointmentError && (
                  <Text
                    color="#F65160"
                    margin="2px 0px 0px 0px"
                    fontSize="12px"
                    width="100%"
                    align="center"
                  >
                    Select an Appointment type
                  </Text>
                )}

                <InputInlineText onClick={() => appointmentRef.current.focus()}>
                  Select Appointment
                </InputInlineText>
                {appointmentDrop && (
                  <DropdownDiv
                    width="100%"
                    id="inFocus2"
                    ref={dropRef4}
                    onClick={() => setAppointmentDrop(false)}
                    style={{ overflowX: "hidden" }}
                  >
                    {filterappointments(searchAppointment).length !== 0 ? (
                      <>
                        {filterappointments(searchAppointment).map((row) => (
                          <DropdownText
                            width="100%"
                            border="none"
                            height="40px"
                            flexCenterVertical
                            name="appointment"
                            key={row.id}
                            onClick={(e) => Appointment_Type(row)}
                          >
                            <NormalImage
                              loading="lazy"
                              src={
                                row.name === "Telehealth"
                                  ? tele_icon
                                  : facility_icon
                              }
                              margin="9px 11px 9px 16px"
                              height="32px"
                              width="32px"
                              circle
                            />
                            {row.name}
                          </DropdownText>
                        ))}
                      </>
                    ) : (
                      <DropdownText
                        width="100%"
                        border="none"
                        flexCenterVertical
                        padding="0 16px"
                      >
                        No Results Found
                      </DropdownText>
                    )}
                  </DropdownDiv>
                )}
              </>
            )}
          </Div>
        </Div>

        {/*  Appointment type End */}

        {/* Provider Start */}

        <Div
          margin="20px 0 0"
          flexCenterBetween
          height={appointmentData["provider"] === "" ? "100px" : "auto"}
        >
          <Div flexCenterVertical>
            <NormalImage
              loading="lazy"
              src={providerIcon}
              width="14.67px"
              height="15.2px"
            />
            <Text
              fontSize="13px"
              fontWeight="400"
              width="auto"
              margin="0px 0px 0px 12px"
            >
              Provider
            </Text>
            {/* {appointmentData["provider"] !== "" && (
              <HiddenButton
                mediaWidth="1100px"
                color="#407BFF"
                fontSize="12px"
                marginLeft="12px"
                background="white"
                cursor="pointer"
                onClick={() => {
                  dispatch(clearInput("provider"));
                }}
              >
                Edit
              </HiddenButton>
            )} */}
          </Div>
          <Div width="55%" height="100%" position="relative">
            {appointmentData["provider"] !== "" ? (
              <SelectedProvider RescheduleData={data} />
            ) : (
              <>
                <AppointmentInput
                  autoComplete="off"
                  errorBorder={providerError}
                  name="provider"
                  placeholder="Search Provider"
                  value={searchProvider}
                  onChange={(e) => {
                    setSearchProvider(e.target.value);
                  }}
                  backgroundImage={providerLogo}
                  ref={providerRef}
                  dropOpen={providerDrop}
                  onClick={() => setProviderDrop(true)}
                  onFocus={() => setProviderDrop(true)}
                  id="aptLst_napt-inp_prdr"
                />
                <BsSearch id="inFocus1" />
                {providerError && (
                  <Text
                    color="#F65160"
                    margin="2px 0px 0px 0px"
                    fontSize="12px"
                    width="100%"
                    align="center"
                  >
                    Select a provider
                  </Text>
                )}
                <InputInlineText onClick={() => providerRef.current.focus()}>
                  Select Provider
                </InputInlineText>
                {providerDrop && (
                  <DropdownDiv
                    width="100%"
                    padding="8px 0px"
                    id="inFocus2"
                    ref={dropRef1}
                  >
                    {providerLoading ? (
                      <DropdownText
                        width="100%"
                        border="none"
                        padding="0 16px"
                        flexCenterVertical
                      >
                        Loading...
                      </DropdownText>
                    ) : (
                      <>
                        {providersList.length !== 0 &&
                        appointmentData["speciality"] !== "" ? (
                          <>
                            {providersList.map((row) => (
                              <DropdownText
                                width="100%"
                                border="none"
                                flexCenterVertical
                                height="32px"
                                name="provider"
                                key={row.id}
                                onClick={(e) => selectProvider(row)}
                              >
                                {row.img_url &&
                                row.img_url.length > 0 &&
                                !imageError.includes(row.id) ? (
                                  <NormalImage
                                    loading="lazy"
                                    height="28px"
                                    width="28px"
                                    borderRadius="50%"
                                    margin="2px 10px 2px 16px"
                                    src={proImageUrl + row.img_url}
                                    onError={() => errorImage(row.id)}
                                  />
                                ) : (
                                  <InitialsText>
                                    {getInitials(row.name)}
                                  </InitialsText>
                                )}
                                {row.name}
                              </DropdownText>
                            ))}
                          </>
                        ) : (
                          <DropdownText
                            width="100%"
                            border="none"
                            flexCenterVertical
                            padding="0 16px"
                          >
                            {appointmentData["office"] !== ""
                              ? "No Results Found"
                              : "Select an office, speciality and appointment type"}
                          </DropdownText>
                        )}
                      </>
                    )}
                  </DropdownDiv>
                )}
              </>
            )}
          </Div>
        </Div>

        {/* Provider End */}

        <Div
          margin="20px 0px 0px 0px"
          display="flex"
          alignItems="start"
          height={appointmentData["apttime"] === "" ? "100px" : "auto"}
          ref={timeDivRef}
          position="relative"
        >
          {appointmentData["showSelector"] ? (
            <>
              <Div flexCenterVertical width="45%">
                <NormalImage
                  loading="lazy"
                  src={dateIcon}
                  height="16px"
                  width="16px"
                />
                <Text
                  fontSize="13px"
                  fontWeight="400"
                  width="auto"
                  margin="0px 0px 0px 12px"
                >
                  Time and date
                </Text>
                {/* {appointmentData["timeSelected"] && (
                  <HiddenButton
                    cursor="pointer"
                    mediaWidth="1100px"
                    onClick={() => dispatch(clearInput("time"))}
                  >
                    Edit
                  </HiddenButton>
                )} */}
              </Div>
              <>
                {!appointmentData["timeSelected"] ? (
                  <Div width="55%" height="100%" position="relative">
                    <InputInlineText
                      onClick={() => timeRef.current.focus()}
                      id="aptLst_napt-p_dt"
                    >
                      Select Time &#38; Date
                    </InputInlineText>

                    <AppointmentInput3
                      errorBorder={
                        timeError["noTime"] || timeError["noProvider"]
                      }
                      backgroundImage={clockLogo}
                      onClick={() =>
                        dispatch(
                          changeAppointmentData({
                            name: "showSelector",
                            value: false,
                          })
                        )
                      }
                      onFocus={() =>
                        dispatch(
                          changeAppointmentData({
                            name: "showSelector",
                            value: false,
                          })
                        )
                      }
                      name="time"
                      ref={timeRef}
                    />
                    {(timeError["noTime"] || timeError["noProvider"]) && (
                      <Text1
                        color="#F65160"
                        margin="2px 0px 0px 0px"
                        className={
                          timeError["noTime"]
                            ? "availabilityerr"
                            : "availabilityerr1"
                        }
                        width="100%"
                        align="center"
                      >
                        {timeError["noTime"]
                          ? "Select a time &s date"
                          : "Select a provider to check availablity"}
                      </Text1>
                    )}
                  </Div>
                ) : (
                  <SelectedOffice Type="time" />
                )}
              </>
            </>
          ) : (
            <TimeDateDiv ref={timeSelectRef}>
              <TimeDateSelector />
            </TimeDateDiv>
          )}
        </Div>

        <Div margin="20px 0px 0px 0px" height="67px" flexCenterBetween>
          <Div flexCenterVertical>
            <NormalImage
              loading="lazy"
              src={reasonIcon}
              height="16px"
              width="16px"
            />
            <Text
              fontSize="13px"
              fontWeight="400"
              width="auto"
              margin="0px 0px 0px 12px"
            >
              Reason for Visit
            </Text>
          </Div>
          <ReasonInput
            placeholder="Enter reason"
            value={appointmentData["reason"]}
            onChange={(e) =>
              dispatch(
                changeAppointmentData({ name: "reason", value: e.target.value })
              )
            }
            id="aptLst_napt-tare_rson"
          />
        </Div>

        <Div width="100%" display="flex" justifyContent="end">
          <Button
            marginTop="37px"
            marginBottom="16px"
            height="40px"
            background="#2C7BE5"
            hoverBackColor="#005FB2"
            color=" #FFF"
            borderRadius="4px"
            width="55%"
            name="submit_button"
            className="save_active"
            disabled={savebtndisabled}
            onClick={saveAppointment}
            id="aptLst_napt-btn_smit"
          >
            Submit
          </Button>
        </Div>
      </DivVariant2>
    </Div>
  );
};
// commit
export default AddAppointment;
