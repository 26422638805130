import React from "react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Div, CommonDiv, Text, Button } from "../../StyledComponents";
import { Avatar, AvatarLetter, AvatarImage } from "../StyledComponents";
import { debounce } from "../../Profile/EditProfile/index";
import { useCallback } from "react";
import Pin from "../../../Assets/images/Pin.png";
import Unpin from "../../../Assets/images/Unpin.png";
import { Image, InputBox } from "./Styles";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { useDispatch, useSelector } from "react-redux";
import {
  getPinchat,
  GetMsgList,
} from "../../../StateManagement/Reducers/MessengerState";
import { StyledSpinner } from "./Spinner";

const HeadNav = ({
  status,
  data,
  handleChatSearch,
  handleUpward,
  handleDownward,
  togg,
  handleTogg,
  provdataid,
  list,
  session,
}) => {
  const [searchText, setsearchText] = useState("");
  const Spinner1 = useSelector((state) => state.PreLogin.spinner);
  const dispatch = useDispatch();
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (firstDot[0] === "client") {
    DBhost = "app";
  } else if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

  const revId =
    localStorage.getItem("Prov_Id") &&
    Decrypt_Value(localStorage.getItem("Prov_Id"), "vozoportal");
  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const handleToggle = () => {
    setsearchText("");
    handleTogg();
  };

  const filteredList = list.filter((data) => data.pindate);

  const pindateCounts = filteredList.reduce((counts, data) => {
    const { pindate } = data;
    counts[pindate] = (counts[pindate] || 0) + 1;
    return counts;
  }, {});
  const totalPinnedCount = Object.values(pindateCounts).reduce(
    (total, count) => total + count,
    0
  );
  const handlePin = (value) => {
    if (list.some((data) => data.id === value && data.pindate === null)) {
      if (totalPinnedCount < 3) {
        let article = {
          sender_id: Pat_ID,
          recip_id: session,
          receive_id: provdataid,
          pin: "pin",
        };

        dispatch(getPinchat(article));
      }
      dispatch(GetMsgList());
    }
  };
  const handlePin1 = (value) => {
    if (list.some((data) => data.id === value && data.pindate !== null)) {
      let article = {
        sender_id: Pat_ID,
        recip_id: session,
        receive_id: provdataid,
        pin: "unpin",
      };

      dispatch(getPinchat(article));
    }
    dispatch(GetMsgList());
  };
  const handleTextSearch = (e) => {
    setsearchText(e.target.value);
    optimizedFn(e.target.value);
  };

  const optimizedFn = useCallback(debounce(handleChatSearch), []);

  return (
    <Div
      padding="20px "
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Div display="flex">
        {data.img_url === null ? (
          <Avatar
            width="40px"
            height="40px"
            border="1px solid #FFFFFF"
            position="relative"
          >
            <AvatarLetter position="absolute" top="11px" right="12px">
              {data.ProviderName.charAt(0).toUpperCase()}
            </AvatarLetter>
          </Avatar>
        ) : (
          <AvatarImage
            src={File_URL + data.img_url}
            alt="image-failed"
            loading="lazy"
            width="40px"
            height="40px"
            border="1px solid #FFFFFF"
            objectFit="unset"
          />
        )}
        <CommonDiv marginLeft="6px">
          <Text
            color="#0a1629"
            lineHeight="22px"
            fontSize="16px"
            fontWeight="600"
          >
            {data.ProviderName}
          </Text>
          <Text
            color={status === "Online" ? "#0EAA5F" : "#91929e"}
            fontSize="14px"
            textAlign="unset"
            fontWeight="400"
            mt="4px"
          >
            {Spinner1 ? <StyledSpinner /> : status}
            {/* {status} */}
          </Text>
        </CommonDiv>
      </Div>
      <Div display="flex">
        <Div display="flex" alignItems="center" justifyContent="end">
          <Div
            display="flex"
            textAlign="right"
            border="1px solid #F0F0F0"
            height="44px"
            marginRight="-10px"
            borderRadius="14px 0 0 14px"
            background="#F7F7F7"
            alignItems="center"
            width={togg ? "80%" : "auto"}
          >
            <Div
              display={togg ? "flex" : "none"}
              background="#F7F7F7"
              alignItems="center"
              width="100%"
            >
              <IoIosArrowUp
                style={{ margin: "0px 16px", cursor: "pointer" }}
                onClick={handleUpward}
              />
              <IoIosArrowDown
                style={{ margin: "0px 16px 0px 0px", cursor: "pointer" }}
                onClick={handleDownward}
              />
              <InputBox
                placeholder="search"
                style={{ border: "none", background: "#F7F7F7", width: "50%" }}
                value={searchText}
                onChange={(e) => handleTextSearch(e)}
              />
            </Div>
          </Div>

          <Button
            color="#000000"
            background="#F7F7F7"
            padding="9px 11px"
            onClick={handleToggle}
          >
            <AiOutlineSearch
              font-size="22px"
              fill="#666666"
              font-weight="100"
              viewBox="0 -200 1024 1024"
            />
          </Button>
        </Div>
        {list.some(
          (data) => data.id === provdataid && data.pindate === null
        ) ? (
          <Button
            background="#FFFFFF"
            border="#FFFFFF"
            padding="4px 0px 0 8px"
            onClick={(e) => handlePin(provdataid)}
            cursor={totalPinnedCount >= 3 ? "not-allowed" : "pointer"}
          >
            <Image width="44px" height="44px" src={Pin} alt="Pin image"></Image>
          </Button>
        ) : (
          <Button
            background="#FFFFFF"
            border="#FFFFFF"
            padding="4px 0px 0 8px"
            onClick={(e) => handlePin1(provdataid)}
          >
            <Image
              width="44px"
              height="44px"
              src={Unpin}
              alt="Unpin image"
            ></Image>
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default HeadNav;
