import { call, put } from "redux-saga/effects";

import {
  SetPatGenderDetails,
  SetPatMaritalStatus,
  SetPatRaceDetails,
  SetPatLangDetails,
  SetEmailExist,
  Setprofileupdatestatus,
  Refresh,
  SetPatientState,
  SetResetPassword,
  SetCheckPortalPassword,
} from "../../StateManagement/Reducers/SettingState";

import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
  PatGenderDetails_Api,
  PatMaritalStatus_Api,
  PatLangDetails_Api,
  PatRaceDetails_Api,
  PatientProfileUpdate_Api,
  PatientProfileRemove_Api,
  PatientDetailUpdate_Api,
  EmailExist_Api,
  ProfileUpdateStatus_Api,
  State_Api,
  Password_Api,
  CheckPortalPassword_Api,
} from "../Apis/SettingsApi";

export function* PatGenderDetailsApi() {
  try {
    const res = yield call(PatGenderDetails_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozo");
      yield put(SetPatGenderDetails(temp_role));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PatMaritalStatusApi() {
  try {
    const res = yield call(PatMaritalStatus_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozo");
      yield put(SetPatMaritalStatus(temp_role));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PatLangDetailsApi() {
  try {
    const res = yield call(PatLangDetails_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozo");
      yield put(SetPatLangDetails(temp_role));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PatRaceDetailsApi() {
  try {
    const res = yield call(PatRaceDetails_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozo");
      yield put(SetPatRaceDetails(temp_role));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PatientProfileUpdateApi({ payload }) {
  const dataArray = payload;

  try {
    const res = yield call(PatientProfileUpdate_Api, dataArray);
    if (res.status === 200) {
      if (res.data === "success") res.data = 1;
      yield put(Refresh(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* PatientProfileRemoveApi() {
  try {
    const res = yield call(PatientProfileRemove_Api);
    if (res.status === 200) {
      if (res.data === "success") res.data = 2;
      yield put(Refresh(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* PatientDetailUpdateApi({ payload }) {
  const updatedetails = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(PatientDetailUpdate_Api, updatedetails);
    if (res.status === 200) {
      if (res.data === "success") res.data = 3;
      if (res.data === "failure") res.data = "Please do Some Changes..!";

      yield put(Refresh(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* EmailExistApi({ payload }) {
  const mailcheck = payload.type;
  try {
    const res = yield call(EmailExist_Api, mailcheck);

    if (res.status === 200) {
      yield put(SetEmailExist(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* ProfileUpdateStatusApi({ payload }) {
  const UpdateStausData = Encrypt_Value(payload, "vozoportal");

  try {
    const res = yield call(ProfileUpdateStatus_Api, UpdateStausData);
    if (res.status === 200) {
      yield put(Setprofileupdatestatus(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PatientStateApi() {
  try {
    const res = yield call(State_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozo");
      yield put(SetPatientState(temp_role));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PasswordValidation({ payload }) {
  console.log(payload, "paylaoddd");
  const passReset = Encrypt_Value(payload, "vozoportal");

  try {
    const res = yield call(Password_Api, passReset);
    if (res.status === 200) {
      yield put(SetResetPassword(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* CheckPrevPassword({ payload }) {
  console.log(payload, "paylaoddd");
  const pid = Encrypt_Value(payload.pid, "vozoportal");
  const curPass = Encrypt_Value(payload.curPass, "vozoportal");

  try {
    const res = yield call(CheckPortalPassword_Api, pid, curPass);
    if (res.status === 200) {
      yield put(SetCheckPortalPassword(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}
