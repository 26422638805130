import { takeLatest } from "redux-saga/effects";

import {
  GetPortalEnable,
  // GetClient,
  GetEmailRes,
  GetReEmailRes,
  GetLogo,
  GetPassRes,
  GetSignUp,
  GetPatientUrl,
  GetLoginStatus,
  GetProvStatus,
} from "../../StateManagement/Reducers/PreLoginState";

import {
  LoginEmailCheckApi,
  LoginPasswordSetApi,
  PasswordResetEmailCheckApi,
  SignUpApi,
  LogoApi,
  PortalEnableApi,
  // ClientApi,
  LogChecking,
  ProvStatusCheck,
} from "./PreLoginWorkers";

export function* PortalEnable() {
  yield takeLatest(GetPortalEnable.type, PortalEnableApi);
}

// export function* ClientSaga() {
//   yield takeLatest(GetClient.type, ClientApi);
// }
export function* LoginEmailCheck() {
  yield takeLatest(GetEmailRes.type, LoginEmailCheckApi);
}

export function* LoginPassWordSet() {
  yield takeLatest(GetPassRes.type, LoginPasswordSetApi);
}

export function* PasswordResetEmailCheck() {
  yield takeLatest(GetReEmailRes.type, PasswordResetEmailCheckApi);
}

export function* SignUp() {
  yield takeLatest(GetSignUp.type, SignUpApi);
}

export function* Logo() {
  yield takeLatest(GetLogo.type, LogoApi);
  yield takeLatest(GetPatientUrl.type, LogoApi);
}

export function* VzPortLogCheck() {
  yield takeLatest(GetLoginStatus.type, LogChecking);
}

export function* GetProviderStatus() {
  yield takeLatest(GetProvStatus.type, ProvStatusCheck);
}
