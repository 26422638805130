import { React, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  GetLogo,
  GetPatientUrl,
} from "../../../StateManagement/Reducers/PreLoginState";
import PortalAccess from "../../../Assets/images/portal_access.png";
import vozoLogo from "../../../Assets/images/Logo.png";

import { BodyContainer, Button } from "../../StyledComponents";
import { ImageContainer, Image } from "../StyledComponents";
import Loading from "../../StyledComponents/Loading";

const AccessDenied = () => {
  const dispatch = useDispatch();
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (firstDot[0] === "client") {
    DBhost = "app";
  } else if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";
  const text = useSelector((state) => state.PreLogin.logo);
  const PatientUrl = useSelector((state) => state.PreLogin.PatientUrl);

  useEffect(() => {
    dispatch(GetLogo(), GetPatientUrl());
  }, [dispatch]);

  return (
    <BodyContainer textAlign="center" Flex="1" height="100%">
      <ImageContainer
        paddingTop="40px"
        marginBottom="15px"
        textAlign="left"
        marginLeft="50px"
      >
        {text.length !== 0 ? (
          <Image
            loading="lazy"
            src={text !== "failed" ? File_URL + text : vozoLogo}
            height="41px"
            width="173px"
            objectFit="contain"
          />
        ) : (
          <Loading />
        )}
      </ImageContainer>

      <Image src={PortalAccess} display="block" width="52%" height="62%" />
      <Button
        background="#407BFF"
        display="block"
        height="50px"
        fontFamily="'Inter',sans-serif"
        fontSize="16px"
        fontWeight="500"
        lineHeight="26px"
        padding="12px 10%"
        borderRadius="8px"
        marginLeft="auto"
        marginRight="auto"
        marginTop="46px"
        cursor="unset"
      >
        You dont have access to {PatientUrl}. Please contact your provider for
        details
      </Button>
    </BodyContainer>
  );
};

export default AccessDenied;
