import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setMode } from "../../../StateManagement/Reducers/PreLoginState";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";
import { getCredentials } from "../../../StateManagement/Reducers/LoginState";
import { NormalImage, Div } from "../../StyledComponents";
import { Container, ImageContainer, Image } from "../StyledComponents";
import {
  TitleBlock,
  Title,
  SubTitle,
  ClientButton,
  Status,
  GoogleContainer,
} from "./Styles";
import { jwtDecode } from "jwt-decode";
import Login from "../../../Assets/images/login.png";
import Sign from "../../../Assets/images/SignUp.png";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import Error from "../../../Modules/StyledComponents/Toastify/Error";

import { useNavigate } from "react-router-dom";
const WelcomeRight = () => {
  const dispatch = useDispatch();
  const text = useSelector((state) => state.PreLogin.logo);
  const LoginResponse = useSelector((state) => state.Login.LoginRes);
  const navigate = useNavigate();
  console.log(text);
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (firstDot[0] === "client") {
    DBhost = "app";
  } else if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";
  const handleMode = (a) => {
    localStorage.removeItem("Mode");
    if (a === "1") {
      let mode = "new";
      dispatch(setMode("new"));
      localStorage.setItem("Mode", Encrypt_Value(mode, "vozoportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    } else {
      let mode = "old";
      dispatch(setMode("old"));
      localStorage.setItem("Mode", Encrypt_Value(mode, "vozoportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };
  const handleSuccess = (response) => {
    // Decode the ID token
    const userObject = jwtDecode(response.credential);
    dispatch(
      getCredentials({
        UserName: userObject.email,
        Password: "",
        source: "googlelogin",
        firstName: userObject.given_name,
        lastName: userObject.family_name ? userObject.family_name : "",
      })
    );
  };

  const handleError = () => {
    console.log("Login failed");
    toast(<Error msg="Password is incorrect!." />, {
      containerId: "B",
      className: "error_badge",
    });
  };
  useEffect(() => {
    if (LoginResponse.status === "LOGIN_SUCCESS") {
      navigate("/login");
    } else if (LoginResponse.status === "Signup failed") {
      toast(<Error msg="Signup failed!." />, {
        containerId: "B",
        className: "error_badge",
      });
    }
  }, [LoginResponse]);
  return (
    <Container width="100%">
      <Div width="50%" marginTop="50px" margin="auto" textAlign="center">
        <TitleBlock>
          <Title color="#1F28EB">Welcome back!&nbsp;</Title>
          <Title color="#000000">Nice to see you again</Title>
        </TitleBlock>
        <SubTitle>
          Use the email your practitioner has on file to sign in—no password
          necessary.
        </SubTitle>
        <Div display=" flex" justifyContent="center" marginTop="10px">
          <ClientButton
            marginright="40px"
            to="/InstantSignIn/New"
            exact="true"
            onClick={() => handleMode("1")}
          >
            <NormalImage
              loading="lazy"
              src={Sign}
              alt="Sign-Up Image"
              width="186px"
              height="138px"
            ></NormalImage>
            <Status>I’M A NEW CLIENT</Status>
          </ClientButton>
          <ClientButton
            to="/InstantSignIn/Old"
            exact="true"
            onClick={() => handleMode("2")}
          >
            <NormalImage
              loading="lazy"
              src={Login}
              alt="Login-Image"
              width="186px"
              height="138px"
              id="wel_img-ext"
            />
            <Status>EXISTING CLIENT</Status>
          </ClientButton>
        </Div>
        <Div marginTop="10px" marginBottom="10px">
          or
        </Div>
        <GoogleContainer>
          <GoogleLogin
            theme="filled_blue" // Change theme to "filled_blue", "outline", etc.
            text="signin_with"
            onSuccess={handleSuccess}
            onError={handleError}
          />
        </GoogleContainer>
      </Div>
    </Container>
  );
};

export default WelcomeRight;
