import React from "react";

import EmptyData from "../../../StyledComponents/EmptyData";

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableHeadCell,
  TableBodyCell,
} from "../../../StyledComponents";
import { Div } from "../../Styles";
import { PaymentStatus, TxtSpan } from "../styles";
import moment from "moment";

const PaymentHistory = ({ PaymentLists }) => {
  return (
    <Div>
      <Table>
        <TableHead>
          <TableRow background="#F7F7F7">
            <TableHeadCell>Process Date</TableHeadCell>
            <TableHeadCell>Amount</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {PaymentLists.length !== 0 ? (
            <>
              {PaymentLists.map((row, ind) => (
                <TableRow key={ind}>
                  <TableBodyCell bodered>
                    <TxtSpan fontWeight="500">
                      {row.PaymentDate
                        ? moment(row.PaymentDate).format("MM/DD/YYYY")
                        : ""}
                    </TxtSpan>
                  </TableBodyCell>

                  <TableBodyCell bodered>
                    <TxtSpan color="#407BFF">{`$ ${parseInt(
                      row.pay_total
                    )}`}</TxtSpan>
                  </TableBodyCell>

                  <TableBodyCell bodered>
                    <PaymentStatus>{`Paid`}</PaymentStatus>
                  </TableBodyCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableBodyCell colSpan={3} textAlign="center">
                <EmptyData />
              </TableBodyCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Div>
  );
};

export default PaymentHistory;
