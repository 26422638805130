import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

import {
  SetCardDetails,
  SetInvoiceList,
  setSingleInvoiceData,
  setStateLogo,
  setSymbol,
  setBase64,
  setExtension,
  SetPaymentHistory,
  Refresh,
  setStripeAllowed,
  setStripePubKey,
  setStripefailed,
} from "../../StateManagement/Reducers/BillingState";

import { SetPayBal } from "../../StateManagement/Reducers/DashboardState";

import {
  CardDetails_Api,
  AddCardDetail_Api,
  EditCardDetail_Api,
  RemoveCardDetail_Api,
  InvoiceList_Api,
  OneInvoiceList_Api,
  Extension_Api,
  Base_Api,
  currency_Api,
  MakePayment_Api,
  PaymentHistory_Api,
} from "../Apis/BillingApi";

import { PayBal_Api } from "../Apis/DashBoardApi";

export function* CardDetailsApi() {
  try {
    const res = yield call(CardDetails_Api);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(SetCardDetails(Data[0]));
      if (Data[2] === "Both gl_values are present.") {
        yield put(setStripeAllowed(true));
      } else {
        yield put(setStripeAllowed(false));
      }
      yield put(setStripePubKey(Data[3]));
    }
  } catch (e) {
    yield put(SetCardDetails([]));
    console.log(e.message);
  }
}

export function* AddCardDetailApi({ payload }) {
  try {
    const res = yield call(AddCardDetail_Api, payload);
    if (res.status === 200) {
      if (res.data === "success") res.data = 1;
      if (res.data === "failure") res.data = 2;
      if (res.data === "Duplicate") res.data = "This Card is Already Exist";
      yield put(Refresh(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* EditCardDetailApi({ payload }) {
  try {
    const res = yield call(EditCardDetail_Api, payload);
    if (res.status === 200) {
      if (res.data.replace(/\s+/g, "") === "success") res.data = 4;

      yield put(Refresh(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* RemoveCardDetailApi({ payload }) {
  try {
    const res = yield call(RemoveCardDetail_Api, payload);
    if (res.status === 200) {
      if (res.data.replace(/\s+/g, "") === "success") res.data = 3;

      yield put(Refresh(res.data));
    }
  } catch (e) {
    yield put(Refresh([]));
    console.log(e.message);
  }
}

export function* InvoiceListApi() {
  try {
    const res = yield call(InvoiceList_Api);

    if (res.status === 200) {
      let Decrypt_Response = Decrypt_Value(res.data, "vozoportal");
      yield put(SetInvoiceList(Decrypt_Response));
    }
  } catch (e) {
    yield put(SetInvoiceList([]));
    console.log(e.message);
  }
}

export function* OneInvoiceListApi({ payload }) {
  const data = Encrypt_Value(payload, "vozoportal");
  try {
    const res = yield call(OneInvoiceList_Api, data);

    if (res.status === 200) {
      let Decrypt_Response = Decrypt_Value(res.data, "vozoportal");

      const res2 = yield call(Extension_Api);

      if (res2.status === 200) {
        let Decrypt_Response = Decrypt_Value(res2.data, "vozo");

        let backurl = "";
        let firstDot = window.location.hostname.split(".");
        let DBhost;
        if (firstDot[0] !== "localhost") {
          backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
            "oemr",
            firstDot[0]
          );
          // DBhost = firstDot[0];
        } else {
          backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
          // DBhost = "default";
        }

        if (firstDot[0] === "client") {
          DBhost = "app";
        } else if (
          firstDot[0] === "localhost" ||
          firstDot[0] === "test" ||
          firstDot[0] === "mumbai" ||
          firstDot[0] === "production" ||
          firstDot[0] === "ehr" ||
          firstDot[0] === "ehr1" ||
          firstDot[0] === "dev"
        ) {
          DBhost = "default";
        } else {
          DBhost = firstDot[0];
        }

        const File_URL =
          backurl +
          "/sites/" +
          DBhost +
          "/documentimg/" +
          Decrypt_Response[4]["gl_value"];
        let imgfile = { url: File_URL };

        const res1 = yield call(Base_Api, imgfile);
        if (res1.status === 200) {
          const res3 = yield call(currency_Api);

          if (res3.status === 200) {
            let Decrypt_Response = Decrypt_Value(res3.data, "vozoportal");
            yield put(setSymbol(Decrypt_Response.gl_value));
          }
          yield put(setBase64(res1.data));
        }
        yield put(setStateLogo(Decrypt_Response[3]["gl_value"]));
        yield put(setExtension(Decrypt_Response[4]["gl_value"]));
      }

      yield put(setSingleInvoiceData(Decrypt_Response));
    }
  } catch (e) {
    yield put(setSingleInvoiceData([]));
    console.log(e.message);
  }
}

export function* MakePaymentApi({ payload }) {
  const PayDetail = payload;
  try {
    const res = yield call(MakePayment_Api, PayDetail);

    if (res.data === "successfully paid") {
      res.data = 5;
    } else if (res.data === "No such customer") {
      res.data = "Payment Failed";

      yield put(setStripefailed(true));
    }
    if (res.status === 200) {
      yield put(Refresh(res.data));
      try {
        const res_1 = yield call(PayBal_Api);
        if (res_1.status === 200) {
          let Decrypt_Response = Decrypt_Value(res_1.data, "vozoportal");
          yield put(SetPayBal(Decrypt_Response));
        }
      } catch (e) {
        yield put(SetPayBal([]));
        console.log(e.message);
      }
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* PaymentHistoryApi() {
  try {
    const res = yield call(PaymentHistory_Api);
    if (res.status === 200) {
      let Decrypt_Response = Decrypt_Value(res.data, "vozoportal");
      yield put(SetPaymentHistory(Decrypt_Response));
    }
  } catch (e) {
    yield put(SetPaymentHistory([]));
    console.log(e.message);
  }
}
