import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Groups from "./Group";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { Div, Button, PageTitle } from "../../StyledComponents";
import { Container, Tabs, TabPanel } from "./styles";

import { IoMdArrowForward } from "react-icons/io";

const Group = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("Problems");
  const [type, SetType] = useState("medical_problem");
  const [opt, setOpt] = useState("1");
  const [probOpt, setProbOpt] = useState(true);
  const [labOpt, setLabOpt] = useState(false);

  let accountType =
    localStorage.getItem("account_type") &&
    Decrypt_Value(localStorage.getItem("account_type"), "vozoportal");

  const handleTabs = (e) => {
    if (e === "1") {
      setOpt("1");
      setProbOpt(true);
      setTitle("Problems");
      SetType("medical_problem");
      setLabOpt(false);
    } else {
      setOpt("2");
      setLabOpt(true);
      setTitle("Lab");
      SetType("lab");
      setProbOpt(false);
    }
  };

  return (
    <>
      <Container>
        <Div padding="20px" display="flex" backColor="none">
          <PageTitle fontWeight="600" fontSize="24px" lineHeight="33px">
            {title}
          </PageTitle>
          <Button
            background="#fafafa"
            borderRadius="90px"
            height="33px"
            border="1px solid #fafafa"
            marginLeft="auto"
            padding="6px 16px 8px 16px"
            color="#667085"
            fontSize="14px"
            lineHeight="19px"
            onClick={() => {
              navigate("/HealthProfile", {
                state: { type: { type } },
              });
            }}
          >
            View All
            <IoMdArrowForward
              viewBox="-120 -100 512 512"
              width="16"
              height="16"
            />
          </Button>
        </Div>
        <TabPanel>
          <Tabs
            active={probOpt}
            line={opt}
            onClick={() => handleTabs("1")}
            id="dbrd_pbm-lst"
          >
            Problems
          </Tabs>

          {accountType === -1 ? (
            <></>
          ) : (
            <Tabs
              active={labOpt}
              onClick={() => handleTabs("2")}
              id="dbrd_lab-lst"
            >
              Lab
            </Tabs>
          )}
        </TabPanel>
        <Groups type={type} />
      </Container>
    </>
  );
};

export default Group;
