import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  GetLoginExist,
  GetLogin,
} from "../../StateManagement/Reducers/DashboardState";

import TopNav from "../TopNav";
import Nextappt from "./NextAppointment";
import BillOverview from "./BillingOverview";
import MyCards from "./MyCards";
import Group from "./Group";
import Medication from "./Medication";
import Loading from "../StyledComponents/Loading";
import Welcome from "./Welcomepopup";

import { BodyContainer, NormalImage, Div } from "../StyledComponents";
import {
  PatientInfo,
  LightText,
  SubContainer,
  Div_1,
  CurveImg,
} from "./Styles";

import BlueCurve from "../../Assets/images/Ellipse_2.png";

const Dashboard = () => {
  const dispatch = useDispatch();

  const [PopupShow, setPopupShow] = useState(false);

  const BillLoading = useSelector((state) => state.Dashboard.BillLoading);
  const HealthLoading = useSelector(
    (state) => state.HealthProfile.HealthLoading
  );
  const PatName = useSelector((state) => state.TopNavbar.userDetails.patname);
  const img_url = useSelector((state) => state.TopNavbar.userDetails.img_url);
  const initials = useSelector((state) => state.TopNavbar.userDetails.initials);

  const LoginFirst = useSelector((state) => state.Dashboard.LoginExist);

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (firstDot[0] === "client") {
    DBhost = "app";
  } else if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  useEffect(() => {
    dispatch(GetLoginExist());
  }, []);
  useEffect(() => {
    if (LoginFirst.first_login === null) {
      setPopupShow(true);
      dispatch(GetLogin());
    }
  }, [LoginFirst]);

  return (
    <>
      <TopNav title="Dashboard" />
      {HealthLoading && BillLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <BodyContainer
          paddingTop="44px"
          paddingRight="28px"
          paddingBottom="80px"
          paddingLeft="36px"
        >
          <PatientInfo>
            {img_url === "" || img_url === null ? (
              <Div
                border="1.5px solid #e9eef9"
                position="relative"
                height="70px"
                width="70px"
                minWidth="70px"
                minHeight="70px"
                borderRadius="50%"
                marginRight="20px"
                padding="4px"
              >
                <CurveImg
                  position="absolute"
                  top="-0.5px"
                  left="38px"
                  width="30px"
                  height="30px"
                  src={BlueCurve}
                />
                <Div
                  background="#A8AEBE"
                  height="100%"
                  width="100%"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="#FFFFFF"
                >
                  {initials}
                </Div>
              </Div>
            ) : (
              <Div
                border="1.5px solid #e9eef9"
                position="relative"
                height="70px"
                width="70px"
                minWidth="70px"
                minHeight="70px"
                borderRadius="50%"
                marginRight="20px"
                padding="4px"
              >
                <CurveImg
                  position="absolute"
                  top="-0.5px"
                  left="38px"
                  width="30px"
                  height="30px"
                  src={BlueCurve}
                />
                <NormalImage
                  loading="lazy"
                  height="100%"
                  width="100%"
                  borderRadius="50%"
                  src={File_URL + img_url}
                />
              </Div>
            )}
            <LightText>Hi, </LightText> {PatName}
          </PatientInfo>

          <SubContainer>
            <Div_1 gridTop height="100%">
              <Nextappt />
              <BillOverview />
              <MyCards />
              <Group />
              <Medication />
            </Div_1>
          </SubContainer>
          <Welcome show={PopupShow} ModalHide={() => setPopupShow(false)} />
        </BodyContainer>
      )}
    </>
  );
};

export default Dashboard;
