import React, { forwardRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import {
  changeAppointmentData,
  changeSlider,
  changeErrorObject,
  changeTime,
  getPortalAppAvailablity,
} from "../../../StateManagement/Reducers/AppointmentsState";

import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import {
  DateInput,
  DateChangeButton,
  SlideContainer,
  Slicedes,
  Slide,
  TimePills,
  NextButton,
  TimeSelectorDiv,
} from "./Styles";
import { Button, Div, NormalImage, Text } from "../../StyledComponents";

import Loading from "../../StyledComponents/Loading";
import leftIcon from "../../../Assets/images/left_arrow_icon.png";
import rightIcon from "../../../Assets/images/right_arrow_icon.png";

const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <DateInput onClick={onClick} ref={ref} onChange={onChange}>
    {value}
  </DateInput>
));

const TimeDateSelector = () => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //
  const [mrng, setMrng] = useState(true);
  const [aftnun, setAftnun] = useState(false);
  const [evng, setEvng] = useState(false);
  const dispatch = useDispatch();

  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );
  const sliderObject = useSelector((state) => state.Appointments.sliderObject);
  const loader = useSelector((state) => state.Appointments.loadAvailablity);

  const PortalAppAvailability = useSelector(
    (state) => state.Appointments.portalAppAvailablity
  );
  const newAvailability = useSelector(
    (state) => state.Appointments.providerAvailablity
  );
  const newEvening = newAvailability.evening.filter(
    (_, index) => index !== newAvailability.evening.length - 1
  );
  const Availablity = { ...newAvailability, evening: newEvening };

  const checkTimeAvailablity = (x, value) => {
    const givenTime = moment(
      appointmentData["aptdate"] + " " + x,
      "YYYY-MM-DD hh:mm a"
    );
    const currentTime = moment();
    const a = moment(givenTime).diff(currentTime, "minutes");

    if (a < 0) {
      return true;
    } else {
      if (value) {
        return true;
      } else {
        return false;
      }
    }
  };

  const clickMorning = () => {
    setMrng(true);
    setAftnun(false);
    setEvng(false);
  };

  const clickAfterNun = () => {
    setAftnun(true);
    setMrng(false);
    setEvng(false);
  };

  const clickEvening = () => {
    setEvng(true);
    setMrng(false);
    setAftnun(false);
  };

  useEffect(() => {
    dispatch(getPortalAppAvailablity());
  }, []);

  // ------------------------------ State and Variables End--------------------------------------------------- //

  return (
    <>
      <TimeSelectorDiv>
        <DateChangeButton
          leftArrow
          onClick={() => {
            let result = moment(appointmentData["aptdate"]).subtract(1, "d");
            let a = result.format("YYYY-MM-DD");
            if (a >= PortalAppAvailability["StartDate"]) {
              dispatch(changeAppointmentData({ name: "aptdate", value: a }));
            }
          }}
        >
          <NormalImage
            loading="lazy"
            src={leftIcon}
            height="55.55%"
            width="55.55%"
            border="none"
          />
        </DateChangeButton>
        <DatePicker
          selected={new Date(appointmentData["aptdate"])}
          dateFormat="MM/dd/yyyy"
          minDate={new Date(PortalAppAvailability["StartDate"])}
          maxDate={new Date(PortalAppAvailability["EndDate"])}
          onChange={(date) => {
            let a = moment(date).format("YYYY-MM-DD");
            dispatch(changeAppointmentData({ name: "aptdate", value: a }));
          }}
          value={new Date(appointmentData["aptdate"])}
          customInput={<CustomInput />}
        />

        <DateChangeButton
          rightArrow
          onClick={() => {
            let result = moment(appointmentData["aptdate"]).add(1, "d");
            let a = result.format("YYYY-MM-DD");
            if (a <= PortalAppAvailability["EndDate"]) {
              dispatch(changeAppointmentData({ name: "aptdate", value: a }));
            }
          }}
        >
          <NormalImage
            loading="lazy"
            src={rightIcon}
            height="55.55%"
            width="55.55%"
          />
        </DateChangeButton>
      </TimeSelectorDiv>

      {!loader && Object.keys(Availablity).length > 0 ? (
        <>
          <Div width="100%" height="31%" position="relative" padding="8px 0px">
            <Div
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="10px"
            >
              <Button
                height="30px"
                width="75px"
                background={mrng ? "#2C7BE5" : "#fff"}
                color={mrng ? "#fff" : "#b7b7b7"}
                border="1px solid #d8e0f0"
                borderRadius="4px"
                onClick={clickMorning}
              >
                Morning
              </Button>
              <Button
                height="30px"
                width="75px"
                background={aftnun ? "#2C7BE5" : "#fff"}
                color={aftnun ? "#fff" : "#b7b7b7"}
                border="1px solid #d8e0f0"
                borderRadius="4px"
                onClick={clickAfterNun}
              >
                Afternoon
              </Button>
              <Button
                height="30px"
                width="75px"
                background={evng ? "#2C7BE5" : "#fff"}
                color={evng ? "#fff" : "#b7b7b7"}
                border="1px solid #d8e0f0"
                borderRadius="4px"
                onClick={clickEvening}
              >
                Evening
              </Button>
            </Div>
            <SlideContainer>
              <Div
                width="100%"
                // height="calc(100% - 20px)"
                flexCenterVertical
                margin="8px 0px 0px"
                position="relative"
              >
                {mrng && (
                  <Slide
                    width="150%"
                    slideLeft={sliderObject["morning"]}
                    absoluteLeft="-50%"
                  >
                    {Availablity["morning"].map((x, i) => (
                      <TimePills
                        width="14%"
                        Selected={
                          appointmentData["apttime"].toLowerCase() ===
                          x["time"].toLowerCase()
                        }
                        key={i}
                        value={x["time"]}
                        onClick={(e) => {
                          if (
                            appointmentData["provider"] === "" ||
                            appointmentData["office"] === ""
                          ) {
                            dispatch(
                              changeErrorObject({
                                name: "noProvider",
                                value: true,
                              })
                            );
                          } else {
                            dispatch(changeTime(e.target.value));
                            dispatch(
                              changeErrorObject({
                                name: "noTime",
                                value: false,
                              })
                            );
                          }
                        }}
                        disabled={checkTimeAvailablity(x["time"], x["disable"])}
                        id={`crt_appt-btn_tim${i}`}
                      >
                        {x["time"]}
                      </TimePills>
                    ))}
                  </Slide>
                )}

                {aftnun && (
                  <Slide
                    width="150%"
                    slideLeft={sliderObject["morning"]}
                    absoluteLeft="-50%"
                  >
                    {Availablity["afternoon"].map((x, i) => (
                      <TimePills
                        width="14%"
                        Selected={
                          appointmentData["apttime"].toLowerCase() ===
                          x["time"].toLowerCase()
                        }
                        key={i}
                        value={x["time"]}
                        onClick={(e) => {
                          if (
                            appointmentData["provider"] === "" ||
                            appointmentData["office"] === ""
                          ) {
                            dispatch(
                              changeErrorObject({
                                name: "noProvider",
                                value: true,
                              })
                            );
                          } else {
                            dispatch(changeTime(e.target.value));
                            dispatch(
                              changeErrorObject({
                                name: "noTime",
                                value: false,
                              })
                            );
                          }
                        }}
                        disabled={checkTimeAvailablity(x["time"], x["disable"])}
                        id={`crt_appt-btn_tim${i}`}
                      >
                        {x["time"]}
                      </TimePills>
                    ))}
                  </Slide>
                )}

                {evng && (
                  <Slide
                    width="150%"
                    slideLeft={sliderObject["evening"]}
                    absoluteLeft="-50%"
                  >
                    {Availablity["evening"].map((x, i) => (
                      <TimePills
                        width="14%"
                        Selected={
                          appointmentData["apttime"].toLowerCase() ===
                          x["time"].toLowerCase()
                        }
                        key={i}
                        value={x["time"]}
                        onClick={(e) => {
                          if (
                            appointmentData["provider"] === "" ||
                            appointmentData["office"] === ""
                          ) {
                            dispatch(
                              changeErrorObject({
                                name: "noProvider",
                                value: true,
                              })
                            );
                          } else {
                            dispatch(changeTime(e.target.value));
                            dispatch(
                              changeErrorObject({
                                name: "noTime",
                                value: false,
                              })
                            );
                          }
                        }}
                        disabled={checkTimeAvailablity(x["time"], x["disable"])}
                        id={`crt_appt-btn_tim${i}`}
                      >
                        {x["time"]}
                      </TimePills>
                    ))}
                  </Slide>
                )}
              </Div>
            </SlideContainer>
          </Div>
        </>
      ) : (
        <Loading position="absolute" height="100%" />
      )}
    </>
  );
};

export default TimeDateSelector;
