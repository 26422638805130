import React from "react";
import { useLocation } from "react-router-dom";

import {
  Vozoiconpic,
  Navicon,
  StyledLink,
  Hrline,
  LogoContainer,
  Nav,
  Ul,
  Li,
} from "./Styles";

const LeftNav = () => {
  const { pathname } = useLocation();
  return (
    <>
      <LogoContainer>
        <Vozoiconpic />
      </LogoContainer>
      <Hrline />
      <Nav>
        <Ul>
          <Li>
            {pathname === "/dashboard" ? (
              <StyledLink to="/dashboard" exact="true">
                <Navicon id="dashboard_img" />
                <Navicon>Dashboard</Navicon>
              </StyledLink>
            ) : (
              <StyledLink to="/" exact="true">
                <Navicon id="dashboard_img" />
                <Navicon id="nav_lnk-spn_dbrd">Dashboard</Navicon>
              </StyledLink>
            )}
          </Li>
          <Li>
            <StyledLink
              to="/appointment/past_appointment"
              className={pathname.includes("/appointment/") && "active"}
              exact="true"
            >
              <Navicon id="appointment_img" />
              <Navicon>Appointment</Navicon>
            </StyledLink>
          </Li>
          <Li>
            <StyledLink to="/billing" exact="true">
              <Navicon id="billing_img" />
              <Navicon id="nav_lnk-spn_bil">Billing</Navicon>
            </StyledLink>
          </Li>

          <Li>
            <StyledLink to="/documents" exact="true">
              <Navicon id="document_img" />
              <Navicon>Documents</Navicon>
            </StyledLink>
          </Li>
          <Li>
            <StyledLink to="/HealthProfile" exact="true">
              <Navicon id="healthprofile_img" />
              <Navicon id="nav_lnk-spn_hltpr">HealthProfile</Navicon>
            </StyledLink>
          </Li>

          <Li>
            <StyledLink to="/settings" exact="true">
              <Navicon id="settings_img" />
              <Navicon>Settings</Navicon>
            </StyledLink>
          </Li>
        </Ul>
      </Nav>
    </>
  );
};

export default LeftNav;
