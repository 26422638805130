import styled from "styled-components";
import { NavLink } from "react-router-dom";
import vozoicon from "../../Assets/images/Vozo_Icon.png";

export const LogoContainer = styled.div`
  margin-top: 26px;
  margin-bottom: 26px;
  display: flex;
`;

export const Vozoiconpic = styled.img.attrs({
  src: vozoicon,
  alt: "image not found",
})`
  margin-left: auto;
  margin-right: auto;
  width: 174px;
  height: 30px;
`;

export const Nav = styled.nav`
  margin-top: 21px;
  padding: 0px 12px 0px 12px;
`;

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  display: flex;
  align-itme: center;
  padding: 14px 0px 14px 15px !important;
`;

export const Navicon = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-position: center;
  background-repeat: no-repeat;
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
`;

export const Hrline = styled.hr`
  border-top: 1px solid #eeeeef;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: none;
`;

export const Ul = styled.ul``;

export const Li = styled.li``;
